import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  validateOnlyCharacters,
  validateEmail,
  validatePasswordWithErrors,
} from "../../shared/helper/validations";

import { Autocomplete, Box, TextField, Typography } from "@mui/material";

const StepOne = ({ users, clientList, setValid, onChange, data }) => {
  const { t } = useTranslation("users");
  const [username, setUsername] = useState(data.username || "");
  const [password, setPassword] = useState(data.password || "");
  const [email, setEmail] = useState(data.email || "");
  const [clients, setClients] = useState(data.clients || []);
  const [type, setType] = useState(data.type || "");
  const [dirty, setDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [errorsPassword, setErrorsPassword] = useState({});

  const types = useMemo(
    () => [
      {
        name: t("Root"),
        value: "Root",
      },
      {
        name: t("User"),
        value: "User",
      },
      {
        name: t("API"),
        value: "API",
      },
    ],
    [],
  );

  // const validatePasswordLocal = useCallback(
  //   (password) => {
  //     if (password) {
  //       if (username === password) {
  //         return t("validationPasswordEqualUsername");
  //       }

  //       if (password && password.length < 8) {
  //         return t("validationPasswordLength");
  //       }

  //       if (!validatePassword(password)) {
  //         return t("validationPassword");
  //       }
  //     } else {
  //       return t("validationPasswordEmpty");
  //     }
  //     return false;
  //   },
  //   [username, t],
  // );

  const validateEmpty = (field) => {
    return field === undefined || field === "" ? false : true;
  };

  const validateEmailLocal = useCallback(
    (email) => {
      if (!validateEmpty(email)) {
        return t("validationEmailEmpty");
      }

      if (!validateEmail(email)) {
        return t("validationEmail");
      }

      if (users) {
        for (let i = 0; i < users.length; i++) {
          if (users[i].email === email) {
            return t("validationEmailAlreadyExist");
          }
        }
        return false;
      }
      return false;
    },
    [t, users],
  );

  const validateUser = useCallback(
    (username) => {
      if (!validateEmpty(username)) {
        return t("users:validationUsernameEmpty");
      }

      if (!validateOnlyCharacters(username)) {
        return t("validationUsernameLetters");
      }

      if (username && username.length < 4) {
        return t("validationUsernameLength");
      }
      //unique username
      if (users) {
        for (let i = 0; i < users.length; i++) {
          if (users[i].username === username) {
            return t("validationUsernameAlreadyExist");
          }
        }
        return false;
      }
      return false;
    },
    [users, t],
  );

  const validate = useCallback(() => {
    const temp = {};
    const tempPass = validatePasswordWithErrors(password, undefined, username);

    const isValidPass = Object.entries(tempPass).every(([key, value]) => {
      if (key === "passwordsMatch") {
        return true;
      }
      return value === true;
    });

    temp.username = validateUser(username);
    temp.email = validateEmailLocal(email);
    temp.clients =
      clients?.length !== 0 || type === "Root"
        ? false
        : t("validationClientsEmpty");
    temp.type = validateEmpty(type) ? false : t("modalErrorTypeRequired");

    setErrors(temp);
    setErrorsPassword(tempPass);

    if (
      isValidPass &&
      password !== "" &&
      Object.values(temp).every((x) => x === false)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [
    validateUser,
    username,
    password,
    validateEmailLocal,
    email,
    t,
    clients,
    type,
    setValid,
  ]);

  useEffect(() => {
    validate();
    onChange({ username, password, email, type, clients });
  }, [username, password, email, type, clients, validate, onChange]);

  const getOptionClientLabel = useMemo(() => (option) => option.name, []);
  const getOptionTypeLabel = useMemo(() => (option) => option.name, []);

  const valueClients = useMemo(
    () => clientList.filter((x) => clients?.includes(x.name)),
    [clientList, clients],
  );

  const valueType = useMemo(
    () => types.find((x) => x.name === type),
    [types, type],
  );

  const handleTypeChange = useCallback(
    (event, newValue) => {
      if (newValue !== null) {
        setType(newValue.name);
        if(newValue.name === t("Root")){
          setClients(clientList.map((client) => client.name));
        } else {
          setClients([]);
        }
      } else {
        setType("");
        !dirty.type && setDirty({ ...dirty, type: true });
      }
    },
    [setType, dirty, setDirty],
  );

  const renderOption = (props, option, { selected }) => (
    <li
      {...props}
      style={{
        backgroundColor: selected ? "#585858" : "transparent"}}
    >
      <Box
        component="span"
        sx={{
          width: 14,
          height: 14,
          mr: 1,
          backgroundColor: selected ? "#585858" : "transparent",
          borderRadius: "50%",
          display: "inline-block",
          verticalAlign: "middle",
        }}
      />
      <span style={{ color: selected ? "black" : "inherit" }}>
        {option.name}
      </span>    </li>
  );

  const handleClientsChange = useCallback(
    (event, newValue) => {
      if (newValue !== null) {
        const clientsNames = newValue.map((value) => value.name);
        setClients(clientsNames);
      } else {
        setClients([]);
      }
      !dirty.clients && setDirty({ ...dirty, clients: true });
    },
    [setClients, dirty, setDirty],
  );

  const renderInputTypes = useMemo(
    () =>
      function renderInput(params) {
        return (
          <TextField
            variant="standard"
            {...params}
            label={t("type") + "*"}
            error={errors.type && dirty.type}
            helperText={errors.type && dirty.type ? errors.type : ""}
          />
        );
      },
    [(t, errors.type, dirty.type)],
  );

  const renderInputClients = useMemo(
    () =>
      function renderInput(params) {
        return (
          <TextField
            variant="standard"
            {...params}
            label={t("clients") + "*"}
            error={errors.clients && dirty.clients}
            helperText={errors.clients && dirty.clients ? errors.clients : ""}
          />
        );
      },
    [t, errors.clients, dirty.clients],
  );

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          id="modal-username"
          variant="standard"
          label={t("username") + "*"}
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={username}
          error={errors.username && dirty.username}
          helperText={errors.username && dirty.username ? errors.username : ""}
          onChange={(e) => {
            setUsername(e.target.value);
            !dirty.username && setDirty({ ...dirty, username: true });
          }}
        />
        <TextField
          id="modal-password"
          variant="standard"
          label={t("password") + "*"}
          sx={{
            width: "75%",
            marginTop: "1rem",
          }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            !dirty.password && setDirty({ ...dirty, password: true });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
            alignItems: "flex-start",
            marginTop: "0.5rem",
          }}>
          <Typography
            id = "password-min-length"
            variant="h7"
            color={
              errorsPassword && errorsPassword.passwordLength
                ? "#3fb950"
                : "error"
            }>
            {(errorsPassword && errorsPassword.passwordLength ? "✓" : "✗") +
              t("passwordLength")}
          </Typography>
          <Typography
            id = "password-min-number"
            variant="h7"
            color={
              errorsPassword && errorsPassword.containsNumber
                ? "#3fb950"
                : "error"
            }>
            {(errorsPassword && errorsPassword.containsNumber ? "✓" : "✗") +
              t("containsNumber")}
          </Typography>
          <Typography
            id = "password-uppercase"
            variant="h7"
            color={
              errorsPassword && errorsPassword.containsUppercase
                ? "#3fb950"
                : "error"
            }>
            {(errorsPassword && errorsPassword.containsUppercase ? "✓" : "✗") +
              t("containsUppercase")}
          </Typography>
          <Typography
            id = "password-lowercase"
            variant="h7"
            color={
              errorsPassword && errorsPassword.containsLowercase
                ? "#3fb950"
                : "error"
            }>
            {(errorsPassword && errorsPassword.containsLowercase ? "✓" : "✗") +
              t("containsLowercase")}
          </Typography>
          <Typography
            id = "password-special-char"
            variant="h7"
            color={
              errorsPassword && errorsPassword.containsSpecialCharacter
                ? "#3fb950"
                : "error"
            }>
            {(errorsPassword && errorsPassword.containsSpecialCharacter
              ? "✓"
              : "✗") + t("containsSpecialCharacter")}
          </Typography>
          <Typography
            id = "password-no-username"
            variant="h7"
            color={
              errorsPassword && errorsPassword.containsUsername
                ? "#3fb950"
                : "error"
            }>
            {(errorsPassword && errorsPassword.containsUsername ? "✓" : "✗") +
              t("containsUsername")}
          </Typography>
        </div>
        <TextField
          id="modal-email"
          variant="standard"
          label={t("email") + "*"}
          sx={{
            width: "75%",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
          value={email}
          error={errors.email && dirty.email}
          helperText={errors.email && dirty.email ? errors.email : ""}
          onChange={(e) => {
            setEmail(e.target.value);
            !dirty.email && setDirty({ ...dirty, email: true });
          }}
        />
        <Autocomplete
          id="modal-type"
          variant="standard"
          options={types}
          getOptionLabel={getOptionTypeLabel}
          value={valueType}
          sx={{ width: "75%", marginTop: "1rem", marginBottom: "1rem" }}
          onChange={handleTypeChange}
          renderInput={renderInputTypes}
        />
        <Autocomplete
          id="modal-clients"
          multiple
          variant="standard"
          options={clientList}
          value={valueClients}
          getOptionLabel={getOptionClientLabel}
          sx={{
            width: "75%",
            marginTop: "1rem",
            marginBottom: "1rem",
            display: type === t("Root") ? "none" : "",
          }}
          onChange={handleClientsChange}
          renderInput={renderInputClients}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderOption={renderOption}
        />
      </Box>
    </div>
  );
};

export default StepOne;
